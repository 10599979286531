import React from "react"
import { Link } from "gatsby"

function BehanceIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      style={{
        fill: "#000000",
        paddingRight: "3px",
      }}
    >
      {" "}
      <path d="M 6 4 C 4.895 4 4 4.895 4 6 L 4 24 C 4 25.105 4.895 26 6 26 L 24 26 C 25.105 26 26 25.105 26 24 L 26 6 C 26 4.895 25.105 4 24 4 L 6 4 z M 17.5 11 L 20.056641 11 C 20.332641 11 20.556641 11.224 20.556641 11.5 C 20.556641 11.776 20.332641 12 20.056641 12 L 17.5 12 C 17.224 12 17 11.776 17 11.5 C 17 11.224 17.224 11 17.5 11 z M 8.9199219 11.384766 L 11.705078 11.384766 C 13.349078 11.384766 14.330078 12.189047 14.330078 13.498047 C 14.330078 14.396047 13.665875 15.179594 12.796875 15.308594 L 12.796875 15.414062 C 13.918875 15.496063 14.734375 16.341594 14.734375 17.433594 C 14.735375 18.918594 13.613688 19.859375 11.804688 19.859375 L 8.9199219 19.859375 C 8.4119219 19.859375 8 19.447453 8 18.939453 L 8 12.304688 C 8 11.796688 8.4119219 11.384766 8.9199219 11.384766 z M 9.7734375 12.740234 L 9.7734375 14.896484 L 11.105469 14.896484 C 12.063469 14.897484 12.585937 14.503266 12.585938 13.822266 C 12.585938 13.147266 12.099906 12.740234 11.253906 12.740234 L 9.7734375 12.740234 z M 18.962891 13.240234 C 20.871891 13.240234 21.998047 14.456719 21.998047 16.511719 L 21.998047 17.033203 L 17.554688 17.033203 L 17.554688 17.121094 C 17.577687 18.084094 18.142156 18.683594 19.035156 18.683594 C 19.710156 18.683594 20.173469 18.443625 20.355469 18.015625 L 21.947266 18.015625 C 21.689266 19.237625 20.572328 20 18.986328 20 C 17.013328 20 15.851563 18.754344 15.851562 16.652344 C 15.851562 14.544344 17.036891 13.240234 18.962891 13.240234 z M 18.970703 14.550781 C 18.177703 14.550781 17.618547 15.101406 17.560547 15.941406 L 20.320312 15.941406 C 20.284313 15.084406 19.768703 14.550781 18.970703 14.550781 z M 9.7734375 16.095703 L 9.7734375 18.503906 L 11.353516 18.503906 C 12.375516 18.503906 12.927734 18.07325 12.927734 17.28125 C 12.927734 16.50525 12.3575 16.095703 11.3125 16.095703 L 9.7734375 16.095703 z"></path>
    </svg>
  )
}

function InstagramIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      style={{
        fill: "#000000",
        paddingRight: "3px",
      }}
    >
      {" "}
      <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"></path>
    </svg>
  )
}

function PinterestIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      style={{
        fill: "#000000",
        paddingRight: "3px",
      }}
    >
      {" "}
      <path d="M 12 2 C 6.477 2 2 6.477 2 12 C 2 17.523 6.477 22 12 22 C 17.523 22 22 17.523 22 12 C 22 6.477 17.523 2 12 2 z M 12 4 C 16.418 4 20 7.582 20 12 C 20 16.418 16.418 20 12 20 C 11.264382 20 10.555494 19.892969 9.8789062 19.707031 C 10.09172 19.278284 10.282622 18.826454 10.386719 18.425781 C 10.501719 17.985781 10.972656 16.191406 10.972656 16.191406 C 11.278656 16.775406 12.173 17.271484 13.125 17.271484 C 15.958 17.271484 18 14.665734 18 11.427734 C 18 8.3227344 15.467031 6 12.207031 6 C 8.1520313 6 6 8.7215469 6 11.685547 C 6 13.063547 6.73325 14.779172 7.90625 15.326172 C 8.08425 15.409172 8.1797031 15.373172 8.2207031 15.201172 C 8.2527031 15.070172 8.4114219 14.431766 8.4824219 14.134766 C 8.5054219 14.040766 8.4949687 13.958234 8.4179688 13.865234 C 8.0299688 13.394234 7.71875 12.529656 7.71875 11.722656 C 7.71875 9.6496562 9.2879375 7.6445312 11.960938 7.6445312 C 14.268937 7.6445313 15.884766 9.2177969 15.884766 11.466797 C 15.884766 14.007797 14.601641 15.767578 12.931641 15.767578 C 12.009641 15.767578 11.317063 15.006312 11.539062 14.070312 C 11.804063 12.953313 12.318359 11.747406 12.318359 10.941406 C 12.318359 10.220406 11.932859 9.6191406 11.130859 9.6191406 C 10.187859 9.6191406 9.4296875 10.593391 9.4296875 11.900391 C 9.4296875 12.732391 9.7109375 13.294922 9.7109375 13.294922 C 9.7109375 13.294922 8.780375 17.231844 8.609375 17.964844 C 8.5246263 18.326587 8.4963381 18.755144 8.4941406 19.183594 C 5.8357722 17.883113 4 15.15864 4 12 C 4 7.582 7.582 4 12 4 z"></path>
    </svg>
  )
}

function LinkedInIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="28"
      height="28"
      viewBox="0 0 32 32"
      style={{
        fill: "#000000",
        paddingRight: "3px",
      }}
    >
      <path d="M 7.5 5 C 6.132813 5 5 6.132813 5 7.5 L 5 24.5 C 5 25.867188 6.132813 27 7.5 27 L 24.5 27 C 25.867188 27 27 25.867188 27 24.5 L 27 7.5 C 27 6.132813 25.867188 5 24.5 5 Z M 7.5 7 L 24.5 7 C 24.785156 7 25 7.214844 25 7.5 L 25 24.5 C 25 24.785156 24.785156 25 24.5 25 L 7.5 25 C 7.214844 25 7 24.785156 7 24.5 L 7 7.5 C 7 7.214844 7.214844 7 7.5 7 Z M 10.4375 8.71875 C 9.488281 8.71875 8.71875 9.488281 8.71875 10.4375 C 8.71875 11.386719 9.488281 12.15625 10.4375 12.15625 C 11.386719 12.15625 12.15625 11.386719 12.15625 10.4375 C 12.15625 9.488281 11.386719 8.71875 10.4375 8.71875 Z M 19.46875 13.28125 C 18.035156 13.28125 17.082031 14.066406 16.6875 14.8125 L 16.625 14.8125 L 16.625 13.5 L 13.8125 13.5 L 13.8125 23 L 16.75 23 L 16.75 18.3125 C 16.75 17.074219 16.996094 15.875 18.53125 15.875 C 20.042969 15.875 20.0625 17.273438 20.0625 18.375 L 20.0625 23 L 23 23 L 23 17.78125 C 23 15.226563 22.457031 13.28125 19.46875 13.28125 Z M 9 13.5 L 9 23 L 11.96875 23 L 11.96875 13.5 Z"></path>
    </svg>
  )
}

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" class="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-home nav-current" role="menuitem">
                <Link to={`/`}>Home</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/resume`}>Resume</Link>
              </li>
              {/* <li className="nav-elements" role="menuitem">
                <Link to={`/elements`}>Elements</Link>
              </li> */}
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://www.instagram.com/srushtisi/"
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.behance.net/srushtisi"
                title="Behance"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BehanceIcon />
              </a>
              <a
                href="https://pinterest.com/srushtisi/"
                title="Pinterest"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PinterestIcon />
              </a>
              {/* <a
                href=""
                title="Medium"
                target="_blank"
                rel="noopener noreferrer"
              >
                Medium
              </a> */}
              <a
                href="https://www.linkedin.com/in/srushti-chiddarwar-40690b226/"
                title="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link> &mdash;
        Built with a whole lot of me ❤️
      </footer>
    </div>
  )
}

export default Layout
